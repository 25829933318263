
import Terms from "./components/Pages/Terms";
import Privacy from "./components/Pages/Privacy";
import Disclaimer from "./components/Pages/Disclaimer";
import { BrowserRouter as Router, Routes, Route, Link, BrowserRouter } from "react-router-dom"; // Import necessary components
import Home from "./components/Pages/Home";


const App = () => {
  return (
<BrowserRouter>
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/term-of-use" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/disclaimer-policy" element={<Disclaimer />} />
        </Routes>
    </BrowserRouter>
  )

}

export default App
