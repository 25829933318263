import React from "react";

const Disclaimer = () => {
  return (
    <div className="mx-14 p-6 text-justify">
      <h1 className="text-3xl font-bold mb-6 text-center underline">
        DISCLAIMER POLICY
      </h1>
      <h2 className="text-2xl font-semibold mb-4">1. INTERPRETATION</h2>
      <p className="mb-4">
        The words with initial capital letters have meanings defined under the
        following conditions. The following definitions shall have the same
        meaning regardless of whether they appear in singular or plural form.
      </p>

      <h2 className="text-2xl font-semibold mb-4">2. DEFINITIONS</h2>
      <ul className="list-disc ml-5 mb-4">
        <li>
          <span className="font-semibold">Company</span> (referred to as either "the Company", "We",
          "Us" or "Our" in this Disclaimer) refers to Amaxa Tech Solutions.
        </li>
        <li>
          <span className="font-semibold">Site</span> refers to the Website.
        </li>
        <li>
          <span className="font-semibold">You</span> means the individual accessing or using the Site,
          or the company or other legal entity on behalf of which such
          individual is accessing or using the Site, as applicable.
        </li>
        <li>
          <span className="font-semibold">Website</span> refers to Amaxa Tech Solutions, accessible
          from <a href="https://www.amaxatech.com" className="text-blue-500">
          www.amaxatech.com
        </a>{" "}
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">3. DISCLAIMER</h2>
      <p className="mb-4">
        The information contained on the Site is for general information
        purposes only. While the Company strives to ensure that the content of
        the Site is up to date and accurate, it assumes no responsibility for
        errors or omissions. The Site and content provided on the Site are "as
        is," without warranties of any kind, whether express or implied,
        including but not limited to implied warranties of merchantability,
        fitness for a particular purpose, and non-infringement.
        In no event shall the Company be liable for any special, direct,
        indirect, consequential, or incidental damages or any damages
        whatsoever, whether in an action of contract, negligence, or other tort,
        arising out of or in connection with the use of the Site or the contents
        of the Site. The author of the content reserves the right to make
        additions, deletions, or modifications to the contents on the Site at
        any time without prior notice. Users should exercise their own judgment
        and due diligence when relying on any information provided on the
        platform.
        The Company does not warrant that the Site is free from viruses,
        malware, or other harmful components.
      </p>

      <h2 className="text-2xl font-semibold mb-4">4. USER GENERATED CONTENT</h2>
      <p className="mb-4">
        The platform may contain user-generated content, including but not
        limited to company profiles, job postings, student resumes, comments,
        and reviews. We do not endorse or guarantee the accuracy, reliability,
        or completeness of any user-generated content. Users are solely
        responsible for the content they submit and should comply with all
        applicable laws, regulations, and our platform guidelines. We reserve
        the right to remove or modify any user-generated content that violates
        our terms of service or is deemed inappropriate, including but not
        limited to content that is offensive, discriminatory, harassing,
        misleading, or infringes on the intellectual property rights of others.
        By submitting content to the platform, you grant us a non-exclusive,
        worldwide, royalty-free license to use, reproduce, modify, adapt,
        publish, translate, create derivative works from, distribute, and
        display such content. Users should be aware of the potential risks
        associated with sharing personal information on the platform and
        exercise caution when interacting with other users. We do not endorse or
        guarantee the accuracy, reliability, or completeness of any third-party
        services integrated with the platform, and users are responsible for
        reviewing the terms of service and privacy policies of such services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        5. EXTERNAL LINKS DISCLAIMER
      </h2>
      <p className="mb-4">
        The platform may contain links to external websites or resources that
        are not owned or controlled by us. We are not responsible for the
        content or practices of any third-party websites, and we do not endorse
        or guarantee the accuracy, reliability, or completeness of any
        information or materials contained therein. You should review the
        privacy policies and terms of service of any third-party websites you
        visit before providing any personal information or engaging in any
        transactions. If you encounter any issues or concerns with a third-party
        website, please contact the website's owner or operator directly. We
        reserve the right to remove or disable links to any third-party website
        that violates our terms of service or is deemed inappropriate. If the
        platform uses affiliate links, we may receive compensation from
        third-party websites or services. If the platform features sponsored
        content, we will clearly indicate that the content is sponsored and
        disclose any material relationships between us and the sponsor. If the
        platform integrates with third-party services (e.g., payment processors,
        analytics tools), users are responsible for reviewing the terms of
        service and privacy policies of those services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. JOB LISTINGS</h2>
      <p className="mb-4">
        The platform may contain job listings posted by third-party employers.
        We do not guarantee the accuracy, availability, or suitability of any
        job listings, and we are not responsible for the actions or decisions of
        employers. Users should conduct their own research and due diligence
        before applying for any job. We do not verify the authenticity or
        legitimacy of employers or job postings. Users should be aware of
        potential scams or fraudulent job offers and exercise caution when
        providing personal information or engaging in transactions with unknown
        parties. We are not responsible for any losses, damages, or harm that
        may result from your interactions with employers or your participation
        in job interviews or employment processes. Employers are required to
        follow our job posting guidelines, which prohibit discriminatory
        language and require accurate job descriptions. We may take steps to
        verify the legitimacy of employers, but we cannot guarantee the
        authenticity of all job postings. Users should report any suspicious
        activity or fraudulent job offers to us immediately. We promote equal
        opportunity in employment and discourage discriminatory practices in job
        postings.
      </p>

      <h2 className="text-2xl font-semibold mb-4">7. STUDENT PROFILES</h2>

      <p className="mb-4">
        Students may create profiles on the platform to showcase their
        qualifications, experience, and career goals. We do not verify the
        accuracy or completeness of student profiles, and we are not responsible
        for any information or materials contained therein. Students should be
        aware of the potential risks associated with sharing personal
        information on the platform and exercise caution when interacting with
        employers or other users. We do not endorse or guarantee the quality of
        student profiles or the suitability of students for specific job
        opportunities. Students are responsible for maintaining the accuracy and
        completeness of their profiles and for updating them as needed. We allow
        students to control the visibility of their profile information and who
        can view their contact details. We implement measures to protect student
        data from unauthorized access or disclosure. Students are prohibited
        from misrepresenting their qualifications or experience on their
        profiles. Students are responsible for ensuring that any content they
        upload to their profiles does not infringe on the intellectual property
        rights of others.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        8. PRIVACY AND DATA SECURITY
      </h2>
      <p className="mb-4">
        We are committed to protecting the privacy of our users. Please refer to
        our separate privacy policy for detailed information on how we collect,
        use, and protect your personal data. We implement reasonable security
        measures to protect your data from unauthorized access, disclosure,
        alteration, or destruction. However, no method of transmission over the
        internet or electronic storage is completely secure. We cannot guarantee
        the absolute security of your data, and you acknowledge that there is a
        risk of unauthorized access or disclosure, despite our best efforts. If
        you have any concerns about the security of your personal information,
        please contact us immediately. In the event of a data breach, we will
        notify you as required by applicable law. We retain user data for as
        long as necessary to fulfill the purposes for which it was collected,
        unless otherwise required by law. You may request access to or transfer
        your personal data in accordance with applicable data protection laws.
        We may use third-party service providers to process user data, and we
        require them to comply with applicable data protection laws and our
        privacy policies.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        9. ERRORS AND OMISSIONS DISCLAIMER
      </h2>
      <p className="mb-4">
        The information provided by the Site is intended for general guidance
        only. Although the Company endeavors to keep the content accurate and up
        to date, errors may occur. Additionally, changes in laws, rules, and
        regulations may result in omissions or inaccuracies in the content. The
        Company is not responsible for any errors or omissions in the
        information provided nor for the outcomes resulting from the use of this
        information.
      </p>
      <h2 className="text-2xl font-semibold mb-4">10. GOVERNING LAW</h2>
      <p className="mb-4">
        This disclaimer shall be governed by and construed in accordance with
        the laws of India.
      </p>
    </div>
  );
};

export default Disclaimer;
