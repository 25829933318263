import Product2 from "../../images/product2.png";

const reviewData = [
  {
    id: 1,
    smallDesc:
      " Automate your interview pipeline with your personalized AI recruiter.",
    image: Product2,
    productName: "Elexis",
    webLink: "https://elexis.ai/"
  },
];

export default reviewData;
