// Footer.js
import React from 'react';
import {
    Link
  } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-white border-gray-300">
      <div className="max-w-screen-xl mx-auto px-4 py-4 flex flex-col md:flex-row justify-between items-center">
        <div className="text-center mt-4 text-gray-500 text-sm">
          © {new Date().getFullYear()} Amaxa Tech Solutions. All rights reserved.
        </div>
        <div className="flex flex-col md:flex-row md:space-x-6 mt-4 md:mt-0">
          <Link to="/term-of-use">Terms of Use</Link>
          <Link to="/disclaimer-policy"> Disclaimer Policy</Link>
          <Link to="/privacy-policy"> Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
