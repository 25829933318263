import React from "react";

const Privacy = () => {
  return (
    <div className="mx-14 p-6 text-justify">
      <h1 className="text-3xl font-bold mb-6 text-center underline">
        Privacy Policy
      </h1>
      <h2 className="text-2xl font-semibold mb-4">1. INTRODUCTION</h2>
      <p className="mb-4">
        Welcome to www.amaxatech.com. This Privacy Policy applies to your use of
        our online services, including our websites, software applications, and
        any related features or functionalities (collectively, the "Services").
        We, Amaxa Tech Solutions ("Amaxa", "we", or "us"), are committed to
        protecting your privacy and maintaining the trust of all our users.
        Here, we strive for transparency by outlining how we collect, use, and
        share your data when you access and interact with our Services.
      </p>
      <h2 className="text-2xl font-semibold mb-4">2. SCOPE</h2>
      <p className="mb-4">
        This Privacy Policy outlines our practices for collecting, using, and
        sharing your personal and non-personal information when you interact
        with our online services, websites, and software. We are committed to
        transparency regarding our data practices.
      </p>

      <h2 className="text-2xl font-semibold mb-4">3. CONSENT</h2>
      <p className="mb-4">
        By accessing or using our website, you agree to be bound by this Privacy
        Policy. Please read this Policy carefully to understand our practices
        regarding the collection, use, and disclosure of your personal
        information. Your acceptance of this Policy at registration signifies
        your consent to our data handling practices as outlined here in.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        4. CHANGES TO THIS PRIVACY POLICY
      </h2>
      <p className="mb-4">
        We reserve the right to update this Privacy Policy at any time. Please
        check this page regularly for any changes. By using our services, you
        agree to be bound by the most recent version of this Policy.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        5. WHAT INFORMATION DO WE COLLECT?
      </h2>
      <p className="mb-4">
        We collect information about you from multiple sources. This includes
        information you provide directly, data we obtain from third parties, and
        information automatically gathered through our services. When you visit
        our platform, your IP address may be recorded. If you use specific
        features, we may request personal information such as your contact
        details, resume, or location data. Additionally, we collect information
        from employers for verification and billing purposes. This information
        may include, without limitation:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <span className="font-semibold">Personal Information:</span> Name,
          email, password, contact details, job title, and employment status.
        </li>
        <li>
          <span className="font-semibold">Contact information:</span> Email
          address, phone number, physical address.
        </li>
        <li>
          <span className="font-semibold">Demographic details:</span> Age, sex,
          occupation, interests.
        </li>
        <li>
          <span className="font-semibold">
            Financial and billing information.
          </span>
        </li>
        <li>
          <span className="font-semibold">Device Information:</span> IP address,
          device type, operating system, browser version, screen resolution, and
          unique device identifiers, web logs, browser details.
        </li>
        <li>
          <span className="font-semibold">Geographic Data:</span> Location data
          based on IP.
        </li>
        <li>
          <span className="font-semibold">Usage Data:</span> Pages visited,
          links clicked, and interaction with website content.
        </li>
      </ul>
      <p className="mb-4">We may also collect sensitive information such as:</p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <span className="font-semibold">
            CV/Resumes and Recruitment Information:
          </span>{" "}
          Work history, education background, skills, and any other relevant
          data provided.
        </li>
        <li>
          <span className="font-semibold">Compensation and Benefits:</span>{" "}
          Salary details, benefits, and other work-related compensation data.
        </li>
        <li>
          <span className="font-semibold">Government Issued Identifiers:</span>{" "}
          Aadhar, PAN, passport details, etc., for legal or regulatory
          compliance.
        </li>
      </ul>
      <p className="mb-4">
        Please note, we may also indirectly collect information about you from
        third-party services integrated into our Site.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        6. CHILDREN’S PERSONAL INFORMATION
      </h2>
      <p className="mb-4">
        This platform is not designed for children under the age of 18. We do
        not intentionally collect personal information from individuals under
        18. If you are under 18 or fall under the age of minority in your local
        jurisdiction, please do not use our services unless you have obtained
        consent from your parent or legal guardian.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        7. HOW WE COLLECT INFORMATION
      </h2>
      <p className="mb-4">
        We collect information from you in the following ways:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <span className="font-semibold">Directly:</span> When you register or
          interact with our platform.
        </li>
        <li>
          <span className="font-semibold">Automatically:</span> Through tracking
          technologies like cookies and web beacons.
        </li>
        <li>
          <span className="font-semibold">From third-party platforms:</span>{" "}
          Such as social media or linked accounts.
        </li>
        <li>
          <span className="font-semibold">From your mobile device:</span> If GPS
          is enabled.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        8. HOW WE USE YOUR INFORMATION
      </h2>
      <p className="mb-4">
        By providing us with your information, you consent to its use, transfer,
        processing, and storage. We process your data for the following
        purposes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <span className="font-semibold">Service Delivery:</span> Matching
          candidates with jobs, offering career guidance, and providing
          recruitment-related services.
        </li>
        <li>
          <span className="font-semibold">Platform Management:</span>{" "}
          Troubleshooting, system administration, and improving user experience.
        </li>
        <li>
          <span className="font-semibold">Marketing:</span> Sending newsletters,
          whitepapers, event updates, and promotional content (with your
          consent). Your information will not be shared with third parties for
          marketing purposes without your consent, except within our corporate
          group or with trusted partners. However, if you make your profile
          public, it will be accessible to third parties.
        </li>
        <li>
          <span className="font-semibold">Legal Compliance:</span> Fulfilling
          obligations related to labor, tax, and social security laws.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        9. WE USE THE INFORMATION WE COLLECT TO:
      </h2>
      <p className="mb-4">
        We use the information we collect for the following purposes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <span className="font-semibold">Platform Enhancement:</span> Improving
          user experience and tailoring our services.
        </li>
        <li>
          <span className="font-semibold">Communication:</span> Providing
          feedback, updates, and support.
        </li>
        <li>
          <span className="font-semibold">Marketing:</span> Sending newsletters,
          promotions, and job alerts (with your consent).
        </li>
        <li>
          <span className="font-semibold">Analytics:</span> Conducting surveys,
          creating reports, and analyzing user behavior.
        </li>
        <li>
          <span className="font-semibold">Legal Compliance:</span> Fulfilling
          legal requirements and responding to judicial processes.
        </li>
        <li>
          <span className="font-semibold">Security:</span> Preventing illegal
          activities, enforcing agreements, and protecting rights.
        </li>
        <li>
          <span className="font-semibold">Partnerships:</span> Working with our
          corporate family and service providers.
        </li>
        <li>
          <span className="font-semibold">Disclosure:</span> Sharing information
          with third parties as required by law or with your consent.
        </li>
      </ul>
      <p className="mb-4">
        We may share your information with our employees and service providers
        who help us manage our platform and provide our services. These
        individuals will have access to your information to fulfill their roles.
        We may analyze the keywords in your resume to match you with job
        opportunities.
      </p>
      <p className="mb-4">
        If you refer someone to our platform, we will collect their information
        to send them an invitation. They can opt out of receiving such
        communications. We will send you a welcome email to verify your account
        and communicate with you regarding your inquiries, service requests, and
        account management. You may also receive SMS alerts.
      </p>
      <p className="mb-4">
        We may share your information with third parties, including social media
        platforms, to provide targeted advertisements and content related to our
        services. We may also supplement your information with data from
        third-party sources.
      </p>
      <p className="mb-4">
        We reserve the right to disclose your personal information as required
        by law or to protect our rights, comply with legal processes, or respond
        to judicial requests.
      </p>

      <h2 className="text-2xl font-semibold mb-4">10. RESUME INFORMATION</h2>
      <p className="mb-4">
        <span className="font-semibold">Resume Visibility and Access:</span>{" "}
        Your resume visibility is determined by your profile settings. You can
        choose to make your profile public, allowing employers and recruiters to
        search for and access your resume. Alternatively, you can keep your
        profile private, restricting access to only the employers you apply to.
        Please note that even if you choose a private profile, employers you
        apply to will still be able to view your resume. However, your resume
        will not be searchable by other users or recruiters.
      </p>
      <p className="mb-4">
        <span className="font-semibold">Text Message Opt-In:</span> You have the
        option to receive text messages from us when employers express interest
        in your resume or job profile. By selecting this option, you consent to
        receiving text messages, emails, or reminders to your registered contact
        information. You can opt out of this service at any time by updating
        your profile settings.
      </p>
      <p className="mb-4">
        <span className="font-semibold">Resume Confidentiality:</span> If your
        profile is public, your resume is non-confidential and accessible to
        anyone. If your profile is private, your resume is confidential and only
        accessible to employers you apply to. While we take reasonable measures
        to protect your data, we cannot guarantee that unauthorized third
        parties won't gain access to your resume. We are not responsible for how
        third parties use your information when your profile is public.
      </p>
      <p className="mb-4">
        <span className="font-semibold">Resume Management:</span> You can edit,
        update, or remove your resume from our platform at any time. However,
        please be aware that third parties who previously accessed our database
        while your resume was visible may have retained a copy. We cannot
        control such retention or be held responsible for the use of your resume
        by these parties.
      </p>
      <p className="mb-4">
        <span className="font-semibold">Job Applications:</span> WIf your
        profile is private but you apply for a job, the prospective employer
        will still be able to retain a copy of your resume and profile.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        11. INFORMATION SHARING AND DISCLOSURE
      </h2>
      <p className="mb-4">We may share your personal information with:</p>
      <ul className="list-disc list-inside mb-4">
      <li><span className="font-semibold">Third-Party Vendors:</span> Who assist us in delivering our services.</li>
        <li>
        <span className="font-semibold"> Legal Authorities:</span> As required by law or in response to legal
          processes.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        12. UPDATING YOUR INFORMATION
      </h2>
      <p className="mb-4">
        We strive to ensure that your personal information is accurate and up to
        date. We provide mechanisms that allow you to update and correct your
        personally identifiable information for many of our services. For
        specific instructions on how to update your information, please refer to
        the help or support pages associated with each service.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        13. COOKIES AND TRACKING TECHNOLOGIES
      </h2>
      <p className="mb-4">
        We use cookies and other tracking technologies to collect information
        about your interactions with our platform. This information helps us
        improve our services, analyze user behavior, and deliver targeted
        content. You can manage your cookie preferences through your browser
        settings.
      </p>

      <h2 className="text-2xl font-semibold mb-4">14. ACCOUNT PROTECTION</h2>
      <p className="mb-4">
        Your password is essential for protecting your account and personal
        information. You are solely responsible for keeping your password
        confidential and for any actions taken using your account. Sharing your
        password or personal information with others may lead to unauthorized
        access and legal consequences. If you suspect your password has been
        compromised, change it immediately to secure your account. We are not
        responsible for any consequences resulting from your failure to maintain
        password security.
      </p>

      <h2 className="text-2xl font-semibold mb-4">15. SECURITY</h2>
      <p className="mb-4">
        We take the security of your data seriously. We utilize a variety of
        commercially reasonable security measures to protect your personal
        information from unauthorized access, disclosure, alteration, or
        destruction. These measures include encryption, passwords, and physical
        security controls.
      </p>
      <p className="mb-4">
        However, no method of transmission over the internet or electronic
        storage is completely secure. While we strive to protect your
        information, we cannot guarantee its absolute security. Like all
        internet platforms, there is a risk that unauthorized third parties may
        unlawfully intercept or access transmissions. We are also not
        responsible for the security practices of third-party websites you may
        access through our platform.
      </p>

      <h2 className="text-2xl font-semibold mb-4">16. DATA RETENTION</h2>
      <p className="mb-4">
        We retain your personal information for as long as necessary to fulfill
        the purposes outlined in this Privacy Policy or as required by law. We
        may also retain your information for a reasonable period after you cease
        using our services to comply with legal obligations, resolve disputes,
        or prevent fraud.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        17. DATA BREACH NOTIFICATION
      </h2>
      <p className="mb-4">
        In the event of a data breach that compromises your personal
        information, we will take reasonable steps to investigate the incident,
        notify affected individuals as required by applicable law, and implement
        measures to prevent future breaches.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        18. LINKS TO THIRD-PARTY WEBSITES
      </h2>
      <p className="mb-4">
        Our platform may contain links to third-party websites. We are not
        responsible for the privacy practices of these websites. Please review
        their privacy policies before providing any personal information.
      </p>

      <h2 className="text-2xl font-semibold mb-4">19. GOOGLE ANALYTICS</h2>
      <p className="mb-4">
        We use Google Analytics to track and report website traffic. Google
        collects and uses this data to monitor our website usage and may share
        it with other Google services. Google may also use this data to
        personalize ads within its advertising network. You can opt out of
        Google Analytics tracking by installing the Google Analytics opt-out
        browser add-on.
      </p>

      <h2 className="text-2xl font-semibold mb-4">20. YOUR RIGHTS</h2>
      <p className="mb-4">
        You have the right to access, rectify, erase, restrict processing,
        object to processing, data portability, and withdraw consent regarding
        your personal information. To exercise these rights, please contact us
        at info@amaxatech.com.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        21. MODIFICATIONS TO THE TERMS
      </h2>
      <p className="mb-4">
        We reserve the right to modify or update these Terms at any time without
        notice. It is your responsibility to review these Terms regularly. Your
        continued use of the platform after any changes constitutes your
        acceptance of the updated Terms.
      </p>

      <h2 className="text-2xl font-semibold mb-4">22. CONTACT US</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at info@amaxatech.com.
      </p>
    </div>
  );
};

export default Privacy;
